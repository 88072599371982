<template>
    <error-layout>
        <h1 class="title">
            {{ $t('common.pages.error.403.title') }}
        </h1>
        <h2 class="subtitle">
            {{ $t('common.pages.error.403.body') }}
        </h2>
        <div class="columns">
            <div class="column is-12">
                <p>
                    <a @click="$router.back()">{{ $t('common.pages.back_to_home') }}</a>
                </p>
            </div>
            <div class="column is-6 is-offset-3">
                <figure class="image">
                    <img src="/img/not-allowed.svg" alt="Not Allowed">
                </figure>
            </div>
        </div>
    </error-layout>
</template>

<script>
    import ErrorLayout from "../../components/layout/ErrorLayout";
    export default {
        name: "NotAllowed",
        components: {ErrorLayout}
    }
</script>

<style scoped>

</style>